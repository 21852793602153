import * as React from 'react';
import PieChart from './basic-components/PieChart';
import { Stack } from "@fluentui/react";
import LineChart from './basic-components/LineChart';
import BarChart from './basic-components/BarChart';
import ScatterChart from './basic-components/ScatterChart';

function DisplayGeneralStats({brandLandscape, ratingDistribution, ratingTrend, reviewVolume}) {
    

    return (
        <div className="row clearfix">
            <div className="col-lg-6 col-md-12">
                <PieChart pieData={ratingDistribution} title={"Rating Distribution"} seriesName={"Pie data"}/>
            </div>
            <div className="col-lg-6 col-md-12">
                <LineChart data={ratingTrend} title={"Rating Trend"} seriesName={"Line data"}/>
            </div>
            <div className="col-lg-6 col-md-12">
                <BarChart data={reviewVolume} title={"Review Volume"} seriesName={"Bar data"}/>
            </div>
            {/* <div className="col-lg-6 col-md-12">
                <ScatterChart data={brandLandscape} title={"Brand Landscape"} seriesName={"Scatter data"}/>
            </div> */}
        </div>
    );

}

export default DisplayGeneralStats;