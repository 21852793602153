import React, { useState } from "react";
import { Nav, Stack, Text, PrimaryButton} from "@fluentui/react";

const navigationStyles /**INavStyles */ = {
  root: {
    height: "100vh",
    boxSizing: "border-box",
    overflowY: "auto",
    // paddingTop: "10vh",
    background: "#858585",
    // width: "200px"
  },
  navItem: {
    // selectors: {
    //   ":hover &": {
    //     backgroundColor: "yellow"
    //   }
    // }
  },
  // linkText: {
  //   color: "white"
  // },
  link: {
    background: "transparent",
    color: "white",
    selectors: {
      ":hover": {
        background: "transparent",
        color: "white"
      }
    }
  }
  // compositeLink: {
  //   selectors: {
  //     ":hover": {
  //       color: "yellow"
  //     }
  //   }
  // }
};


const links = [
  {
    links: [
      {
        name: "Dashboard",
        key: "key1",
        url: "#",
        iconProps: {
          iconName: "News",

        }
      },
      {
        name: "Product Profiles",
        key: "key2",
        url: "#",
        iconProps: {
          iconName: "PlayerSettings",

        }
      },
      {
        name: "Marketing Autopilot",
        key: "key3",
        url: "#",
        iconProps: {
          iconName: "SwitcherStartEnd",

        }
      }
    ]
  }
];

const stackStyles = {
  root: {
    background: "green",
    height: 100
  }
};
const items = links[0].links.map((l, i) => {
  return {
    key: `${l.key}`,
    name: `${l.name}`,
    // iconProps: { ...l.iconProps, styles: { root: classNames.greenIcon } }
  };
});
const commandItems = [
  {
    key: "menu",
    name: (
      <PrimaryButton 
        // iconProps={{ iconName: "CollapseMenu" }}
        text="Primary"
      />
    ),
    subMenuProps: {
      items: items,
      onMenuOpened: () => console.log("onMenuOpened"),
      onMenuDismissed: () => console.log("onMenuDismissed")
    }
  }
];
function Navigation(props) {
  const [navOpened, setNavOpened] = useState(false);
  return (
    <Stack>
      <div className="header">
        <Stack
          horizontal
          verticalAlign="center"
          className="ms-sm1  ms-hiddenLgUp"
        > 
        </Stack>
      </div>
      <div className="body">
        <Stack 
            vertical
            className="nav-bar"
        >
              <Nav
                groups={links}
                selectedKey="key1"
                styles={navigationStyles}
              />
        </Stack>
      </div>
    </Stack>
  );
}

export default Navigation;
