export const ratingDistribution =[
    {
       name:"oneStar",
       value:927
    },
    {
       name:"twoStar",
       value:151
    },
    {
       name:"threeStar",
       value:181
    },
    {
        name:"fourStar",
        value:305
    },
    {
        name:"fiveStar",
        value:1641
    }
 ];

 export const reviewVolumeByTime	=[
    {
        name:"Oct 2019",
        value:47
    },
    {
        name:"Jan 2020",
        value:104
    },
    {
        name:"Apr 2020",
        value:201
    },
    {
        name:"Jul 2020",
        value:243
    },
    {
        name:"Oct 2020",
        value:323
    },
    {
        name:"Jan 2021",
        value:501
    },
    {
        name:"Apr 2021",
        value:325
    },
    {
        name:"Jul 2021",
        value:267
    },
    {
        name:"Oct 2021",
        value:293
    },
    {
        name:"Jan 2022",
        value:246
    },
    {
        name:"Apr 2022",
        value:414
    },
    {
        name:"Jul 2022",
        value:241
    }
 ];

 export const averageRatingByTime	=[
    {
        name:"Oct 2019",
        value:3.49
    },
    {
        name:"Jan 2020",
        value:3.66
    },
    {
        name:"Apr 2020",
        value:3.47
    },
    {
        name:"Jul 2020",
        value:3.62
    },
    {
        name:"Oct 2020",
        value:3.86
    },
    {
        name:"Jan 2021",
        value:3.85
    },
    {
        name:"Apr 2021",
        value:3.41
    },
    {
        name:"Jul 2021",
        value:3.02
    },
    {
        name:"Oct 2021",
        value:3.24
    },
    {
        name:"Jan 2022",
        value:3.25
    },
    {
        name:"Apr 2022",
        value:3.19
    },
    {
        name:"Jul 2022",
        value:3.81
    }
 ];

export const ThemesRankedByTotalMentions	=[
    {
       name:"Performance",
       value:1062,
       star:3.5,
       percentInReviews:33.14
    },
    {
        name:"Durability",
        value:746,
        star:2,
        percentInReviews:23.28
    },
    {
        name:"Service",
        value:551,
        star:2.3,
        percentInReviews:17.19
    },
    {
        name:"Features",
        value:357,
        star:4.4,
        percentInReviews:11.14
    },
    {
        name:"Users Experience",
        value:345,
        star:4.1,
        percentInReviews:10.76
    },
    {
        name:"Usage",
        value:223,
        star:4,
        percentInReviews:6.96
    },
    {
        name:"Appearance",
        value:26,
        star:3.7,
        percentInReviews:0.81
    }
 ];

 export const	NightyDaysTrend	=[
    {
       keywords:"Buttons not working",
       sentiment:"Negative",
       mentions:32,
       changePercent:18
    },
    {
       keywords:"Good Picture ",
       sentiment:"Positive",
       mentions:19,
       changePercent:-5
    },
    {
       keywords:"Returning",
       sentiment:"Negative",
       mentions:14,
       changePercent:-26
    },
    {
       keywords:"Poor Instruction",
       sentiment:"Negative",
       mentions:15,
       changePercent:-17
    }
 ];		

 export const	KeywordsWiththeMostImpact	=[
    {
       name:"Compact Size",
       totalMentions:229,
       averageRatings:4.6,
       value:0.1
    },
    {
        name:"Installation",
       totalMentions:345,
       averageRatings:4.1,
       value:0.1
    },
    {
        name:"Connection",
       totalMentions:367,
       averageRatings:3.4,
       value:-0.1
    },
    {
        name:"Instructions",
       totalMentions:185,
       averageRatings:3.1,
       value:-0.1
    },
    {
        name:"does not power on",
       totalMentions:166,
       averageRatings:1.9,
       value:-0.1
    },
    {
        name:"Jammed",
       totalMentions:58,
       averageRatings:1.7,
       value:-0.1
    },
    {
        name:"Remote",
       totalMentions:513,
       averageRatings:3.4,
       value:-0.2
    },
    {
        name:"Refund",
       totalMentions:267,
       averageRatings:1.5,
       value:-0.2
    },
    {
        name:"Longivity",
       totalMentions:576,
       averageRatings:2,
       value:-0.4
    }
 ];

 export const	topicsRankedByTotalMentions	=[
    {
       name:"	Longivity	",
       value:576,
       star:2,
       percentInReviews:17.97
    },
    {
       name:"	Remote	",
       value:513,
       star:3.4,
       percentInReviews:16.01
    },
    {
       name:"	Picture Resolution	",
       value:492,
       star:4,
       percentInReviews:15.35
    },
    {
       name:"	Connection	",
       value:367,
       star:3.4,
       percentInReviews:11.45
    },
    {
       name:"	Installation	",
       value:345,
       star:4.1,
       percentInReviews:10.76
    },
    {
       name:"	Refund	",
       value:267,
       star:1.5,
       percentInReviews:8.33
    },
    {
       name:"	Compact	",
       value:229,
       star:4.6,
       percentInReviews:7.15
    },
    {
       name:"	Sound	",
       value:213,
       star:3.4,
       percentInReviews:6.65
    },
    {
       name:"	Instructions	",
       value:185,
       star:3.1,
       percentInReviews:5.77
    },
    {
       name:"	Easy to Use	",
       value:168,
       star:4.3,
       percentInReviews:5.24
    },
    {
       name:"	Turn on	",
       value:166,
       star:1.9,
       percentInReviews:5.18
    },
    {
       name:"	Service	",
       value:118,
       star:3.2,
       percentInReviews:3.68
    },
    {
       name:"	Other features	",
       value:107,
       star:4.1,
       percentInReviews:3.34
    },
    {
       name:"	Function	",
       value:98,
       star:3.3,
       percentInReviews:3.06
    },
    {
       name:"	Home	",
       value:95,
       star:3.9,
       percentInReviews:2.96
    },
    {
       name:"	Personalization	",
       value:87,
       star:3.3,
       percentInReviews:2.71
    },
    {
       name:"	Seller	",
       value:75,
       star:2.3,
       percentInReviews:2.34
    },
    {
       name:"	Interface	",
       value:74,
       star:2.4,
       percentInReviews:2.31
    },
    {
       name:"	Travel	",
       value:61,
       star:4.3,
       percentInReviews:1.90
    },
    {
       name:"	Warranty	",
       value:61,
       star:2.3,
       percentInReviews:1.90
    },
    {
       name:"	Jammed	",
       value:58,
       star:1.7,
       percentInReviews:1.81
    },
    {
       name:"	Noise	",
       value:41,
       star:2.1,
       percentInReviews:1.28
    },
    {
       name:"	Errors	",
       value:35,
       star:1.7,
       percentInReviews:1.09
    },
    {
       name:"	Email	",
       value:35,
       star:2.7,
       percentInReviews:1.09
    },
    {
       name:"	Region Free	",
       value:32,
       star:3.8,
       percentInReviews:1.00
    },
    {
       name:"	Freeze	",
       value:30,
       star:1.5,
       percentInReviews:0.94
    },
    {
       name:"	Appearance	",
       value:22,
       star:3.8,
       percentInReviews:0.69
    },
    {
       name:"	Transportation	",
       value:16,
       star:4.8,
       percentInReviews:0.50
    },
    {
       name:"	Lightweight	",
       value:16,
       star:4.8,
       percentInReviews:0.50
    },
    {
       name:"	Outdoor	",
       value:14,
       star:4.1,
       percentInReviews:0.44
    },
    {
       name:"	Phone	",
       value:11,
       star:1.7,
       percentInReviews:0.34
    },
    {
       name:"	Office	",
       value:10,
       star:3.8,
       percentInReviews:0.31
    },
    {
       name:"	School	",
       value:6,
       star:4.7,
       percentInReviews:0.19
    },
    {
       name:"	Construction	",
       value:6,
       star:3.2,
       percentInReviews:0.19
    }
 ];