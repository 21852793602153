import * as React from 'react';
import { Stack } from '@fluentui/react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

function DisplayMetrics(props) {
    const spinnerStyles= {
        circle: {
          height: 70,
          width: 70,
          borderWidth: 4
        },
        label: {
            fontSize: '20px'
        }
      };

    return (
        <Stack style={{
            marginTop: "350px"
        }}>
            <Spinner styles={spinnerStyles} label="We are processing your request, please check back later..." labelPosition="top" size={SpinnerSize.large} />
        </Stack>
    );
}

export default DisplayMetrics;