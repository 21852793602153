import * as React from 'react';
import HonrizontalBarChart from './basic-components/HorizontalBarChart';
import WordCloud from './basic-components/WordCloud';
import NightyDaysTrend from './basic-components/NightyDayTrendTable';
import BarChart from './basic-components/BarChart';

function DisplayTopicStats({topicsRankedByTotalMentions, keywordsWiththeMostImpact, nightyDaysTrend, negativeTopics, positiveTopics, reviewThemes}) {
    

    return (
        <div class="container">
            {/* <div class="row">
                <div class="col">
                <WordCloud data={themesForWC} title={""} seriesName={"#fcc400"}/>
                </div>
            </div> */}
        <div class="row">
            <div class="col">
                <HonrizontalBarChart data={reviewThemes} title={"Themes Ranked By Total Mentions"} seriesName={"#fcc400"}/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <HonrizontalBarChart data={topicsRankedByTotalMentions} title={"Topics Ranked By Total Mentions"} seriesName={"#fcc400"}/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <HonrizontalBarChart data={positiveTopics} title={"Positive Review Topics"} seriesName={"#40a349"}/>
            </div>
            <div class="col">
                <HonrizontalBarChart data={negativeTopics} title={"Negative Review Topics"} seriesName={"#7f3010"}/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <NightyDaysTrend data={nightyDaysTrend}/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <BarChart data={keywordsWiththeMostImpact} title={"keywords With the Most Impact"}/>
            </div>
        </div>
        </div>
    );

}

export default DisplayTopicStats;