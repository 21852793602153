import * as React from 'react';
  
function NightyDaysTrend(props) {

    const {data} = props;

  return (
    <div className="App">
      <table style={{
        border: "2px solid forestgreen",
        width: "1290px",
        height: "300px"
      }}>
        <tr>
          <th>Keywords</th>
          <th>Sentiment</th>
          <th>Mentions</th>
          <th>Change Percent</th>
        </tr>
        {data.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.keywords}</td>
              <td>{val.sentiment}</td>
              <td>{val.mentions}</td>
              <td>{val.changePercent}</td>
            </tr>
          )
        })}
      </table>
    </div>
  );
}
  
export default NightyDaysTrend;