import * as React from 'react';
import ReactEcharts from "echarts-for-react";


function PieChart(props) {
    const {pieData, title, seriesName} = props

    const dataNames = pieData.map(i => i.name);

    //Chart style
const style = {
    height: "700px",
    width: "100%",
      background: "rgb(152 173 169 / 35%)",
      transition: ".5s",
      border: 0,
      marginBottom: "30px",
      borderRadius: "0.55rem",
      position: "relative",
      boxShadow: "0 1px 2px 0 rgb(0 0 0 / 10%)"
  };
  
  //Chart options
  let option = {
    // backgroundColor: "rgb(43, 51, 59)",
    toolbox: {
      show: true,
      feature: {
        mark: {
          show: true
        },
        magicType: {
          show: true,
          type: ["pie", "funnel"]
        },
        restore: {
          show: true,
          title: "Restore"
        },
        saveAsImage: {
          show: true,
          title: "Save Image"
        }
      }
    },
    graphic: [
      {
        type: "group",
        rotation: Math.PI / 4,
        bounding: "raw",
        right: 200,
        bottom: 100,
        z: 100,
      }
    ],
    // Hover Tooltip
    // {a} = series:[{name:}]
    // {b} = series:[{data: [{name:}]}]
    // {c} = series:[{data: [{value:}]
    tooltip: {
      trigger: "item",
      formatter: "{a}<br/><strong>{b}</strong>: {c}"
    },
    title: {
      text: title,
      left: "center",
      // top: "15%",
      // textStyle: {
      //   color: "#ccc"
      // }
    },
    calculable: true,
    legend: {
      icon: "circle",
      x: "center",
       y: "5%",
      data: dataNames,
      // textStyle: {
      //   color: "#000000"
      // }
    },
    series: [
      {
        name: seriesName,
        type: "pie",
        animationDuration: 2000,
        animationEasing: "quarticInOut",
        radius: [10, 150],
        avoidLabelOverlap: false,
        startAngle: 90,
        hoverOffset: 5,
        center: ["50%", "50%"],
        roseType: "area",
        selectedMode: "multiple",
        label: {
          normal: {
            show: true,
            formatter: "{b}" // {c} data: [{value:},]
          },
          emphasis: {
            show: true
          }
        },
        labelLine: {
          normal: {
            show: true,
            smooth: false,
            length: 20,
            length2: 10
          },
          emphasis: {
            show: true
          }
        },
        data: pieData
      }
    ]
  };

    return (
        <ReactEcharts option={option} style={style} className="pie-chart" />
    );

}

export default PieChart;