import { Flex, Text } from "@aws-amplify/ui-react";
import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import {createPost} from "../graphql/mutations"
import { isValidInputTimeValue } from "@testing-library/user-event/dist/utils";


// const formState = { productName: '', productCategory: '', activity: '', keywords: '' };

const initialState = { productName: '', productCategory: '', activity: '', keywords: '' };

function PostGenPage(props) {
    const [formState, setFormState] = React.useState(initialState);
    const [queryResponse, setQueryResponse] = React.useState("");

    function updateFormState(key, value) {
        setFormState({ ...formState, [key]: value });
        console.log("setting formState", formState);
    }

    async function generatePost() {

        const requestData = {
            productName: formState.productName,
            productCategory: formState.productCategory,
            activity: formState.activity,
            keyWords: formState.keywords
        }
        console.log("formState: " + formState['productName'])
        console.log("sending a request: ", requestData)
        try {
            const res = await API.graphql(graphqlOperation(createPost, requestData));
            console.log(res);
            const createPostResponse = res.data.createPost;
            console.log(createPostResponse);
            // const obj = JSON.parse(createPostResponse);
            
            // console.log("obj", obj);
            setQueryResponse(createPostResponse);
        } catch (err) {
            console.log('error creating post:', err)
        }
    }
    React.useEffect(() => {
        console.log("complete rendering the page, formState", formState);
    }, []);

    return (
        <Flex>
            <Flex style={{
                margin: "50px"
            }}>
                Product Name: <input placeholder="Product Name" onChange={e => updateFormState('productName', e.target.value)} value={formState.productName}/>
            </Flex>
            <Flex style={{
            margin: "50px"
            }}>
                    Product Category: <input placeholder="Product Category" onChange={e => updateFormState('productCategory', e.target.value)} value={formState.productCategory}/>
            </Flex>
            <Flex style={{
            margin: "50px"
            }}>
                    Activity/Occasion: <input placeholder="Activity/Occasion" onChange={e => updateFormState('activity', e.target.value)} value={formState.activity}/>
            </Flex>
            <Flex style={{
            margin: "50px"
            }}>
                    Key Features (separate with comma): 
            </Flex>
            <Flex style={{
            margin: "50px"
            }}>
                    <input style={{
                        width: "1000px",
                        height: "500px",
                        align: "top"
                    }} placeholder="Key Features" onChange={e => updateFormState('keywords', e.target.value)} value={formState.keywords}/>
            </Flex>
            <Flex style={{
                margin: "50px"
            }}>
                <button onClick={generatePost}>Generate Your Social Post</button>
            </Flex>
            <Flex style={{
                margin: "50px"
            }}>
                <Text>{queryResponse}</Text>
            </Flex>
        </Flex>
    );
}

export default PostGenPage;