import * as React from 'react';
import ReactEcharts from "echarts-for-react";

function BarChart(props) {
    const {data, title, seriesName} = props

    const dataNames = data.map(i => i.name);
    const dataVals = data.map(i => i.value);

    //Chart style
const style = {
    height: "700px",
    width: "100%",
      background: "rgb(152 173 169 / 35%)",
      transition: ".5s",
      border: 0,
      marginBottom: "30px",
      borderRadius: "0.55rem",
      position: "relative",
      boxShadow: "0 1px 2px 0 rgb(0 0 0 / 10%)"
  };
  
  //Chart options
  let option = {
        xAxis: {
          data: dataNames
        },
        yAxis: {},
        series: [
          {
            data: dataVals,
            type: 'bar'
          },
          
        ],
        title: {
          text: title,
          left: "center",
          // top: "15%",
        },
        toolbox: {
            show: true,
            feature: {
              restore: {
                show: true,
                title: "Restore"
              },
              saveAsImage: {
                show: true,
                title: "Save Image"
              }
            }
          },
    };
    

    return (
        <ReactEcharts option={option} style={style} className="bar-chart" />
    );

}

export default BarChart;