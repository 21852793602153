import React, { useState } from "react";
import {PrimaryButton, Stack, Modal  } from "@fluentui/react";
import AddProductPage from "./AddProductPage";

function AddProjectCard(props) {

    const [isModalOpen, setIsModelOpen] = useState(false);
    const showModal = () => setIsModelOpen(true);
    const hideModal = () => setIsModelOpen(false);
    const cancelIcon = { iconName: 'Cancel' };


    return(
        <div className="col-lg-3 col-md-6 col-sm-6" style={{marginTop: "30px"}}>
          <PrimaryButton 
                onClick={showModal}
                text="Add New Product" 
            />
            <Modal
                isOpen={isModalOpen}
                onDismiss={hideModal}
                isBlocking={false}
            >
                <div>
                <AddProductPage/>
                </div>
            </Modal> 
      </div>
    );
}

export default AddProjectCard;