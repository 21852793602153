import React from "react";
import AddProjectCard from "./AddProjectCard";
import ProductCard from "./ProductCard";

function ProjectListPage(props) {

    const cardData = [
        {
            heading: "DVD Players for TV with HDMI",
            id: "B07Z7YMQYT"
        },
        {
            heading: "VANESSA PRO Flat Iron Hair Straightener",
            id: "B08735PR2V"
        }
    ]
   
    return(
        <div className="container-fluid" style={{
            width: "100%",
            paddingRight: "15px",
            paddingLeft: "15px",
            marginRight: "auto",
            marginLeft: "auto"
        }}>
            <AddProjectCard style={{}}
                index={"1"}
                key={cardData[0].heading}
                heading={cardData[0].heading}
                isRandomUpdate={true}
                // Data={data.sparklineData}
                ContainerClass="col-lg-3 col-md-6 col-sm-6"
                />
            <div className="row clearfix" style={{
                marginTop: "30px"
            }}>
            {cardData.map((data, i) => (
                <ProductCard
                index={i}
                key={data.heading}
                heading={data.heading}
                id = {data.id}
                isRandomUpdate={true}
                // Data={data.sparklineData}
                ContainerClass="col-lg-3 col-md-6 col-sm-6"
                />
            ))}
            </div>
        </div>
    );

}

export default ProjectListPage;