export const ratingDistribution =[
    {
       name:"oneStar",
       value:270
    },
    {
       name:"twoStar",
       value:95
    },
    {
       name:"threeStar",
       value:89
    },
    {
        name:"fourStar",
        value:248
    },
    {
        name:"fiveStar",
        value:1761
    }
 ];

 export const	reviewVolumeByTime	=[
    {
        name:"Oct 2019",
        value:27
    },
    {
        name:"Jan 2020",
        value:22
    },
    {
        name:"Apr 2020",
        value:129
    },
    {
        name:"Jul 2020",
        value:124
    },
    {
        name:"Oct 2020",
        value:396
    },
    {
        name:"Jan 2021",
        value:659
    },
    {
        name:"Apr 2021",
        value:249
    },
    {
        name:"Jul 2021",
        value:148
    },
    {
        name:"Oct 2021",
        value:178
    },
    {
        name:"Jan 2022",
        value:114
    },
    {
        name:"Apr 2022",
        value:165
    },
    {
        name:"Jul 2022",
        value:226
    },
    {
        name:"Oct 2022",
        value:26
    }
 ];

 export const	averageRatingByTime	=[
    {
        name:"Oct 2019",
        value:5
    },
    {
        name:"Jan 2020",
        value:4.18
    },
    {
        name:"Apr 2020",
        value:4.74
    },
    {
        name:"Jul 2020",
        value:4.37
    },
    {
        name:"Oct 2020",
        value:4.65
    },
    {
        name:"Jan 2021",
        value:4.49
    },
    {
        name:"Apr 2021",
        value:4.1
    },
    {
        name:"Jul 2021",
        value:3.44
    },
    {
        name:"Oct 2021",
        value:3.73
    },
    {
        name:"Jan 2022",
        value:3.77
    },
    {
        name:"Apr 2022",
        value:4.22
    },
    {
        name:"Jul 2022",
        value:4.2
    },
    {
        name:"Oct 2022",
        value:2.85
    }
 ];

 export const	NightyDaysTrend	=[
    {
       keywords:"Easy to use",
       sentiment:"Positive",
       mentions:41,
       changePercent:86
    },
    {
       keywords:"Curls ",
       sentiment:"Positive",
       mentions:41,
       changePercent:28
    },
    {
       keywords:"Left hair Shine",
       sentiment:"Positive",
       mentions:9,
       changePercent:350
    },
    {
       keywords:"Glides hair well",
       sentiment:"Positive",
       mentions:9,
       changePercent:125
    }
 ];																		

export const	ThemesRankedByTotalMentions	=[
    {
       name:"USE CASE",
       value:239,
       star:4.6,
       percentInReviews:9.70
    },
    {
        name:"Feature",
        value:166,
        star:4.3,
        percentInReviews:6.74
    },
    {
        name:"Performance",
        value:563,
        star:4.5,
        percentInReviews:22.86
    },
    {
        name:"Safety",
        value:222,
        star:4.1,
        percentInReviews:9.01
    },
    {
        name:"Users Experience",
        value:313,
        star:4.8,
        percentInReviews:12.71
    },
    {
        name:"Look",
        value:263,
        star:4.7,
        percentInReviews:10.68
    },
    {
        name:"Hair Type",
        value:1240,
        star:4.4,
        percentInReviews:50.35
    },
    {
        name:"Hair style/result",
        value:602,
        star:4.6,
        percentInReviews:24.44
    },
    {
        name:"Durability",
        value:322,
        star:3.1,
        percentInReviews:13.07
    },
    {
        name:"Price",
        value:212,
        star:4.7,
        percentInReviews:8.61
    },
    {
        name:"Service",
        value:171,
        star:2.6,
        percentInReviews:6.94
    }
 ];

 export const	KeywordsWiththeMostImpact	=[
    {
       name:"fast",
       totalMentions:215,
       averageRatings:4.8,
       value:0.1
    },
    {
        name:"quick",
       totalMentions:224,
       averageRatings:4.8,
       value:0.1
    },
    {
        name:"sleek",
       totalMentions:162,
       averageRatings:4.7,
       value:0.1
    },
    {
        name:"easy to use",
       totalMentions:239,
       averageRatings:4.8,
       value:0.1
    },
    {
        name:"Smoothness",
       totalMentions:241,
       averageRatings:4.5,
       value:0.1
    },
    {
        name:"curling",
       totalMentions:279,
       averageRatings:4.70,
       value:0.1
    },
    {
        name:"button",
       totalMentions:64,
       averageRatings:2.50,
       value:-0.1
    }
 ];

 export const	topicsRankedByTotalMentions	=[
    {
       name:"Travel",
       value:122,
       star:4.7,
       percentInReviews:4.95
    },
    {
       name:	"Gift"	,
       value:125,
       star:4.5,
       percentInReviews:5.08
    },
    {
       name:	"Office & Schools"	,
       value:9,
       star:3.8,
       percentInReviews:0.37
    },
    {
       name:	"Home"	,
       value:18,
       star:3.6,
       percentInReviews:0.73
    },
    {
       name:	"Gym"	,
       value:1,
       star:5,
       percentInReviews:0.04
    },
    {
       name:	"Bathroom"	,
       value:9,
       star:4.1,
       percentInReviews:0.37
    },
    {
       name:	"Car"	,
       value:0,
       star:0,
       percentInReviews:0.00
    },
    {
       name:	"Compact"	,
       value:30,
       star:4.7,
       percentInReviews:1.22
    },
    {
       name:	"Lightweight"	,
       value:33,
       star:4.8,
       percentInReviews:1.34
    },
    {
       name:	"Portable"	,
       value:1,
       star:5,
       percentInReviews:0.04
    },
    {
       name:	"diffuser"	,
       value:0,
       star:0,
       percentInReviews:0.00
    },
    {
       name:	"attachment"	,
       value:4,
       star:4.8,
       percentInReviews:0.16
    },
    {
       name:	"locking"	,
       value:14,
       star:3.9,
       percentInReviews:0.57
    },
    {
       name:	"wireless"	,
       value:0,
       star:0,
       percentInReviews:0.00
    },
    {
       name:	"Personalization"	,
       value:69,
       star:4.3,
       percentInReviews:2.80
    },
    {
       name:	"Cord"	,
       value:49,
       star:4.4,
       percentInReviews:1.99
    },
    {
       name:	"Fast"	,
       value:478,
       star:4.7,
       percentInReviews:19.41
    },
    {
       name:	"Function"	,
       value:58,
       star:3.3,
       percentInReviews:2.35
    },
    {
       name:	"sound"	,
       value:12,
       star:3.3,
       percentInReviews:0.49
    },
    {
       name:	"Airflow"	,
       value:0,
       star:0,
       percentInReviews:0.00
    },
    {
       name:	"gripping hair"	,
       value:13,
       star:4.5,
       percentInReviews:0.53
    },
    {
       name:	"heat"	,
       value:97,
       star:4,
       percentInReviews:3.94
    },
    {
       name:	"smell"	,
       value:27,
       star:3.3,
       percentInReviews:1.10
    },
    {
       name:	"surge"	,
       value:0,
       star:0,
       percentInReviews:0.00
    },
    {
       name:	"pull hair"	,
       value:127,
       star:4.3,
       percentInReviews:5.16
    },
    {
       name:	"comfortable"	,
       value:14,
       star:4.8,
       percentInReviews:0.57
    },
    {
       name:	"handle"	,
       value:102,
       star:4.4,
       percentInReviews:4.14
    },
    {
       name:	"awkward when using"	,
       value:40,
       star:3.8,
       percentInReviews:1.62
    },
    {
       name:	"easy to use"	,
       value:239,
       star:4.8,
       percentInReviews:9.70
    },
    {
       name:	"Appearance"	,
       value:0,
       star:0,
       percentInReviews:0.00
    },
    {
       name:	"Packaging"	,
       value:53,
       star:4.9,
       percentInReviews:2.15
    },
    {
       name:	"Free Gift"	,
       value:72,
       star:4.6,
       percentInReviews:2.92
    },
    {
       name:	"Thick hair"	,
       value:171,
       star:4.4,
       percentInReviews:6.94
    },
    {
       name:	"Thin Hair"	,
       value:19,
       star:3.9,
       percentInReviews:0.77
    },
    {
       name:	"frizzy"	,
       value:63,
       star:3.9,
       percentInReviews:2.56
    },
    {
       name:	"curly/wavy"	,
       value:165,
       star:4.3,
       percentInReviews:6.70
    },
    {
       name:	"for type 4 hair"	,
       value:53,
       star:4.4,
       percentInReviews:2.15
    },
    {
       name:	"for short hair"	,
       value:19,
       star:4.6,
       percentInReviews:0.77
    },
    {
       name:	"colored"	,
       value:16,
       star:4.9,
       percentInReviews:0.65
    },
    {
       name:	"baby hair"	,
       value:4,
       star:3,
       percentInReviews:0.16
    },
    {
       name:	"beard"	,
       value:1,
       star:5,
       percentInReviews:0.04
    },
    {
       name:	"damaged hair"	,
       value:60,
       star:4.1,
       percentInReviews:2.44
    },
    {
       name:	"Smoothness"	,
       value:241,
       star:4.5,
       percentInReviews:9.78
    },
    {
       name:	"Softness"	,
       value:58,
       star:4.8,
       percentInReviews:2.35
    },
    {
       name:	"volume"	,
       value:7,
       star:4.9,
       percentInReviews:0.28
    },
    {
       name:	"curling"	,
       value:279,
       star:4.7,
       percentInReviews:11.33
    },
    {
       name:	"results"	,
       value:55,
       star:4.5,
       percentInReviews:2.23
    },
    {
       name:	"shine"	,
       value:44,
       star:4.4,
       percentInReviews:1.79
    },
    {
       name:	"scalp"	,
       value:6,
       star:5,
       percentInReviews:0.24
    },
    {
       name:	"bangs"	,
       value:14,
       star:4.9,
       percentInReviews:0.57
    },
    {
       name:	"bob/lob"	,
       value:5,
       star:5,
       percentInReviews:0.20
    },
    {
       name:	"spiraled"	,
       value:1,
       star:1,
       percentInReviews:0.04
    },
    {
       name:	"pixie"	,
       value:0,
       star:0,
       percentInReviews:0.00
    },
    {
       name:	"waves"	,
       value:38,
       star:4.4,
       percentInReviews:1.54
    },
    {
       name:	"Longivity"	,
       value:278,
       star:3.1,
       percentInReviews:11.29
    },
    {
       name:	"button"	,
       value:64,
       star:2.5,
       percentInReviews:2.60
    },
    {
       name:	"Email"	,
       value:4,
       star:1.5,
       percentInReviews:0.16
    },
    {
       name:	"Phone"	,
       value:3,
       star:2.3,
       percentInReviews:0.12
    },
    {
       name:	"Seller"	,
       value:13,
       star:2.1,
       percentInReviews:0.53
    },
    {
       name:	"Refund"	,
       value:9,
       star:1.1,
       percentInReviews:0.37
    },
    {
       name:	"Warranty"	,
       value:13,
       star:2.8,
       percentInReviews:0.53
    },
    {
       name:	"Service"	,
       value:15,
       star:3.5,
       percentInReviews:0.61
    }
 ];