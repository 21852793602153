import React from "react";
import { CommandBar, FontIcon, SearchBox, Stack, Text } from "@fluentui/react";


const searchBoxStyles = { root: { width: 200 } };

function HeaderBar(props) {
    const _items = [
        {
          key: 'newItem',
          text: 'New',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
          iconProps: { iconName: 'Add' },
          subMenuProps: {
            items: [
              {
                key: 'emailMessage',
                text: 'Email message',
                iconProps: { iconName: 'Mail' },
                ['data-automation-id']: 'newEmailButton', // optional
              },
              {
                key: 'calendarEvent',
                text: 'Calendar event',
                iconProps: { iconName: 'Calendar' },
              },
            ],
          },
        },
        {
          key: 'upload',
          text: 'Upload',
          iconProps: { iconName: 'Upload' },
          subMenuProps: {
            items: [
              {
                key: 'uploadfile',
                text: 'File',
                preferMenuTargetAsEventTarget: true,
              },
              {
                key: 'uploadfolder',
                text: 'Folder',
                preferMenuTargetAsEventTarget: true,
              },
            ],
          },
        },
        {
          key: 'share',
          text: 'Share',
          iconProps: { iconName: 'Share' },
          onClick: () => console.log('Share'),
        },
        {
          key: 'download',
          text: 'Download',
          iconProps: { iconName: 'Download' },
          onClick: () => console.log('Download'),
        },
      ];
  return (
    <Stack horizontal>
        <FontIcon iconName="AlignJustifyIcon" styles={"fontSize: 50"}/>
        <Text block variant="large">
            Review Insights
        </Text>
        <Stack verticalAlign="center">
            <SearchBox styles={searchBoxStyles} placeholder="Search" onSearch={newValue => console.log('value is ' + newValue)} />     
        </Stack>
        <CommandBar
        items={_items}
      />
    </Stack>
  );
}

export default HeaderBar;
