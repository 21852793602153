import React, { useLayoutEffect } from "react";
import { Dropdown, Nav, Toast } from "react-bootstrap";
import { Link, useHistory, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import UserImage from "../assets/images/user.png";
import '../assets/assets/scss/metisMenu/metisMenu.scss';
import {CommandBarButton, Nav as NavMenu} from "@fluentui/react";
import logoImg from "../assets/logo/targetTapLogo.png"
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { initializeIcons } from '@fluentui/react/lib/Icons';

function NavbarMenu(props) {
    // const [isModalOpen, setIsModelOpen] = useState(false);
    // const showModal = () => setIsModelOpen(true);
    // const hideModal = () => setIsModelOpen(false);
    // // { setTrue: showModal, setFalse: hideModal }
    // const cancelIcon = { iconName: 'Cancel' };
    const {activeKey} = props;
    initializeIcons(undefined, { disableWarnings: true });
    console.log("activeKey:", activeKey);

      const history = useHistory();
      const NavButton = (props) => {
        console.log("navName in navbarMenu", props.navName)
        return (
        <Route render={({ history}) => (
          <CommandBarButton style={{}}
            onClick={() => { history.replace(`/${props.navName}`) }}
          >
            {props.navName}
          </CommandBarButton>
        )} />
      )
        };

      const renderGroupHeader = (group) => ( <h6>{group.name}</h6>)
      const baseUrl = process.env.PUBLIC_URL;
      console.log("!!! baseUrl", baseUrl);
      const navLinkGroups = [
        {
          name: 'CUSTOMER INSIGHTS',
          links: [
            { name: 'Project Page',  onClick: () => history.replace("/dashboard"), key: 'key1', target: '_blank' },
          ],
        },
        {
          name: 'Social Posts Autopilot',
          links: [
            { name: 'Campaign Report',  onClick: () => history.replace("/dashboard"), key: 'key2', target: '_blank' },
            { name: 'Calender',  onClick: () => history.replace("/dashboard"), key: 'key3', target: '_blank' },
            { name: 'Make a New Post',  onClick: () => history.replace("/PostGen"), key: 'key4', target: '_blank' },
          ],
        },
        {/*add more manu below*/}
      ];

      const iconClass = mergeStyles({
        fontSize: 50,
        height: 50,
        width: 50,
        margin: '0 25px',
      });

    
    return (
        <div>
            <nav className="navbar navbar-fixed-top" style={{
                    padding: "0!important",
                    position: "fixed",
                    top: "5px",
                    left: "0",
                    width: "100%",
                    background: "#fff",
                    zIndex: "99",
                    borderBottom: "1px solid #eee"
            }}>
                <div className="container-fluid" style={{
                    width: "100%",
                    height: "60px",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    marginRight: "auto",
                    marginLeft: "auto"
                }}>
                    <div className="navbar-brand" style = {{
                            padding: "15px 0",
                            position: "relative"
                    }}>
                    <a href="dashboard">
                        <img src={logoImg} style={{
                              width: "230px",
                              marginTop: "-20px"
                        }}></img>
                    </a>
                    </div>
                    <div className="navbar-right" style={{  width: "calc(100% - 550px)"}}>
                    {/** search bar */}
                    {/* <form id="navbar-search" className="navbar-form search-form" style={{
                        float: "left",
                        marginTop: "6px",
                        marginRight: "20px",
                        padding: "0!important",
                        position: "relative"
                    }}>
                        <input
                        className="form-control"
                        placeholder="Search here..."
                        type="text"
                        />
                        <button type="button" className="btn btn-default">
                        <i className="icon-magnifier"></i>
                        </button>
                    </form> */}
                    </div>
                    <div id="navbar-menu">
                        <ul className="nav navbar-nav">
                          <div>
                          <FontIcon style={{
                            fontSize: "25px",
                            height: "50px",
                            width: "15px"
                          }} iconName="Ringer" className={iconClass} />
                          <FontIcon style={{
                            fontSize: "25px",
                            height: "50px",
                            width: "15px"
                          }} iconName="Mail" className={iconClass} />
                          </div>
                        </ul>
                        </div>
                </div>
            </nav>

            <div id="left-sidebar" className="sidebar" style={{ 
                zIndex: 9,
                width: "250px",
                height: "calc(100vh - 67px)",
                float: "left",
                marginTop: "67px",
                position: "fixed",
                left: "5px",
                backgroundColor: "#f4f7f6",
                 }}>
                <div className="sidebar-scroll">
                    <div className="user-account" style={{
                        textAlign: "left",
                        margin: "20px"
                    }}>
                        <img
                            src={UserImage}
                            className="rounded-circle user-photo"
                            alt="User Profile Picture"
                            style={{
                                marginRight: "10px",
                                width: "50px",
                                verticalAlign: "top",
                                border: "2px solid #dfdfdf",
                                borderRadius: "50%!important"
                            }}
                        />
                        <Dropdown style={{
                                textAlign: "left",
                                marginTop: "5px",
                                display: "inline-block",
                                position: "relative"
                        }}>
                            <span>Welcome, </span>
                            <Dropdown.Toggle
                            variant="none"
                            as="a"
                            id="dropdown-basic"
                            className="user-name"
                            >
                            <strong>Alizee Thomas</strong>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu-right account">
                            <Dropdown.Item href="profilev2page">
                                <i className="icon-user"></i>My Profile
                            </Dropdown.Item>
                            <Dropdown.Item href="appinbox">
                                {" "}
                                <i className="icon-envelope-open"></i>Messages
                            </Dropdown.Item>
                            <Dropdown.Item>
                                {" "}
                                <i className="icon-settings"></i>Settings
                            </Dropdown.Item>
                            <li className="divider"></li>
                            <Dropdown.Item href="login">
                                {" "}
                                <i className="icon-power"></i>Logout
                            </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <hr />
                    </div>
                    <div className="tab-content p-l-0 p-r-0">
                        <div
                            className={"tab-pane active show"}
                            id="menu"
                        >
                            <Nav id="left-sidebar-nav" className="sidebar-nav" style={{
                                  position: "relative",
                                  padding: "13px 15px",
                                  outlineWidth:"0",
                                  color: "#17191c",
                                  fontSize: "15px",
                                  // borderLeftWidth: "5px",
                                  // borderLeftStyle: "solid",
                                  // borderLeftColor: "transparent"
                            }}>
                                <ul id="main-menu" className="metismenu" style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%"
                                }}>
                                    {/* Dashboard */}
                                    <li className="" id="dashboradContainer" style={{
                                        flex: "1 1",
                                        display: "flex",
                                        flexDirection: "column",
                                        position: "relative",
                                        paddingBottom: "5px",
                                    }}>
                                        {/* <a
                                            href="#!"
                                            className="has-arrow"
                                            style={{
                                              color: "#b5b5b5"
                                            }}
                                            onClick={(e) => {
                                            e.preventDefault();
                                            activeMenutabContainer("dashboradContainer");
                                            }}
                                        >
                                            <i className="icon-home"></i> 
                                            
                                            <span style={{
                                              color: "#17191c",
                                              fontSize: "15px"
                                            }}>Customer Insights</span>
                                        </a>
                                        <ul className="collapse">
                                            <li
                                            className={activeKey === "dashboard" ? "active" : ""}
                                            >
                                            <NavButton navName={"dashboard"} />
                                            </li>
                                        </ul> */}
                                        <NavMenu
                                          onRenderGroupHeader={renderGroupHeader}
                                          groups={navLinkGroups}
                                        />
                                    </li>
                                    {/* CustomerInsights */}
                                    {/* <li id="CustomerInsightsContainer" className="" style={{
                                        flex: "1 1",
                                        display: "flex",
                                        flexDirection: "column",
                                        position: "relative",
                                        paddingBottom: "5px"
                                    }}>
                                        <a
                                            href="#!"
                                            className="has-arrow"
                                            onClick={(e) => {
                                            e.preventDefault();
                                            activeMenutabContainer("CustomerInsightsContainer");
                                            }}
                                        >
                                            <i className="icon-grid"></i> <span>App</span>
                                        </a>
                                        <ul className="collapse">
                                            <li
                                            className={"active"}
                                            onClick={() => {}}
                                            >
                                            <Link to="appinbox">Inbox</Link>
                                            </li>
                                            <li
                                            className={activeKey === "appchat" ? "active" : ""}
                                            onClick={() => {}}
                                            >
                                            <Link to="appchat">Chat</Link>
                                            </li>
                                            <li
                                            className={
                                                activeKey === "appcalendar" ? "active" : ""
                                            }
                                            onClick={() => {}}
                                            >
                                            <Link to="appcalendar">Calendar</Link>
                                            </li>
                                            <li
                                            className={activeKey === "appcontact" ? "active" : ""}
                                            onClick={() => {}}
                                            >
                                            <Link to="appcontact">Contact Card</Link>
                                            </li>
                                            <li
                                            className={activeKey === "apptaskbar" ? "active" : ""}
                                            onClick={() => {}}
                                            >
                                            <Link to="apptaskbar">Taskboard</Link>
                                            </li>
                                        </ul>
                                    </li> */}
                                    
                                    
                                </ul>
                            </Nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );

}

export default NavbarMenu;