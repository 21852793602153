/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($id: Int!, $name: String!) {
    createCustomer(id: $id, name: $name) {
      id
      name
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($id: Int) {
    deleteCustomer(id: $id) {
      id
      name
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $productName: String!
    $productCategory: String!
    $activity: String!
    $keyWords: String!
  ) {
    createPost(
      productName: $productName
      productCategory: $productCategory
      activity: $activity
      keyWords: $keyWords
    )
  }
`;
