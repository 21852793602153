import React from "react";
import { Route } from "react-router-dom";

function ProductCard(props) {
    const {heading, index, id} = props;
    // const history = useHistory();

    // function handleClick() {
    //   history.push(`/${index}`);
    // }
    const Button = () => (
      <Route render={({ history}) => (
        <button
          type='button'
          onClick={() => { history.replace(`/new-location?id=${id}`) }}
        >
          View Product Analytics
        </button>
      )} />
    )

    return(
        <div className="col-lg-3 col-md-6 col-sm-6" style={{marginBottom: "15px"}}>
        <div className="card overflowhidden number-chart">
          <div className="body">
            <div className="number">
              <h6>{heading}</h6>
            </div>
          </div>
          <div
            id={"main" + index}
            className="sparkline"
            style={{ width: "100%", height: 55 }}
          ></div>
          {/* <button type="button" onClick={handleClick}>
            Go home
          </button> */}
          <Button/>
        </div>
      </div>
    );

}

export default ProductCard;