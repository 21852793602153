import * as React from 'react';
import {Pivot, PivotItem } from '@fluentui/react';
import DisplayMetrics from "./DisplayMetrics";
import DisplayGeneralStats from './DisplayGeneralStats';
import DisplayTopicStats from './DisplayTopicStats';
import DisplayVoCHightlights from './DisplayVoCHightlights';
import * as B08735PR2V_data from '../data/B08735PR2V';
import * as B07Z7YMQYT_data from '../data/B07Z7YMQYT'

// 
function ProductAnalyticsPage(props) {
    const productId = (new URLSearchParams(window.location.search)).get("id");
    var product_data;
    switch(productId) {
        case "B08735PR2V":
            product_data = B08735PR2V_data;
            break;
        case "B07Z7YMQYT":
            product_data = B07Z7YMQYT_data;
            break;
    }

    // TODO: add graphql query to get loading state, error state, and data
    // {
    //     loading: true,
    //     data: undefined,
    //     error: undefined
    // }
    // {
    //     loading: false,
    //     data: {...},
    //     error: null
    // }
    const loading = true;

    // Data for general statistics
    const brandLandscape = [
        {
            brand: "Tymo",
            numberOfReviews: 5092,
            averageRating: 4.4
        },
        {
            brand: "HSI",
            numberOfReviews: 5036,
            averageRating: 3.8
        },
        {
            brand: "Remington",
            numberOfReviews: 5012,
            averageRating: 4.3
        },
        {
            brand: "BabylissPRO",
            numberOfReviews: 3396,
            averageRating: 4
        },
        {
            brand: "FURIDEN",
            numberOfReviews: 3077,
            averageRating: 4.2
        },
        {
            brand: "Bekind",
            numberOfReviews: 3012,
            averageRating: 4.5
        },
        {
            brand: "Panasonic",
            numberOfReviews: 2940,
            averageRating: 3.3
        },
        {
            brand: "GHD",
            numberOfReviews: 2627,
            averageRating: 4.3
        },
        {
            brand: "VANESSA",
            numberOfReviews: 2281,
            averageRating: 4.3
        },
        {
            brand: "Shark",
            numberOfReviews: 2029,
            averageRating: 4.1
        }
    ];

    // const ratingDistribution = [
    //     {
    //         name: "oneStar",
    //         value: 2974
    //     },
    //     {
    //         name: "twoStar",
    //         value: 1055
    //     },
    //     {
    //         name: "threeStar",
    //         value: 1190
    //     },
    //     {
    //         name: "fourStar",
    //         value: 1532
    //     },
    //     {
    //         name: "fiveStar",
    //         value: 10790
    //     }
    // ]

    // const reviewVolume = [
    //     {
    //         name: "sep_21",
    //         value: 1216
    //     },
    //     {
    //         name: "oct_21",
    //         value: 1383
    //     },
    //     {
    //         name: "nov_21",
    //         value: 1408
    //     },
    //     {
    //         name: "dec_21",
    //         value: 1792
    //     },
    //     {
    //         name: "jan_22",
    //         value: 1942
    //     },
    //     {
    //         name: "feb_22",
    //         value: 1524
    //     },
    //     {
    //         name: "mar_22",
    //         value: 1708
    //     },
    //     {
    //         name: "apr_22",
    //         value: 1615
    //     },
    //     {
    //         name: "may_22",
    //         value: 1575
    //     },
    //     {
    //         name: "jun_22",
    //         value: 1370
    //     },
    //     {
    //         name: "jul_22",
    //         value: 1617
    //     },
    //     {
    //         name: "aug_22",
    //         value: 391
    //     }
    // ]

    // const ratingTrend = [
    //     {
    //         name: "jun_21",
    //         value: 4.64
    //     },
    //     {
    //         name: "jul_21",
    //         value: 4.54
    //     },
    //     {
    //         name: "aug_21",
    //         value: 4.62
    //     },
    //     {
    //         name: "sep_21",
    //         value: 4.33
    //     },
    //     {
    //         name: "oct_21",
    //         value: 3.77
    //     },
    //     {
    //         name: "nov_21",
    //         value: 4.08
    //     },
    //     {
    //         name: "dec_21",
    //         value: 4.06
    //     },
    //     {
    //         name: "jan_22",
    //         value: 3.99
    //     },
    //     {
    //         name: "feb_22",
    //         value: 3.76
    //     },
    //     {
    //         name: "mar_22",
    //         value: 3.99
    //     },
    //     {
    //         name: "apr_22",
    //         value: 4.2
    //     },
    //     {
    //         name: "may_22",
    //         value: 3.78
    //     }
    // ]

    // const reviewThemes = [
    //     {
    //         name: "Hair Type",
    //         value: 32166,
    //         star : 4.26
    //     },
    //     {
    //         name: "Hair Style & Result",
    //         value: 29878,
    //         star : 4.38
    //     },
    //     {
    //         name: "Performance",
    //         value: 24321,
    //         star : 3.95
    //     },
    //     {
    //         name: "Use Case",
    //         value: 21542,
    //         star : 4.29
    //     },
    //     {
    //         name: "User Experience",
    //         value: 20524,
    //         star : 4.33
    //     },
    //     {
    //         name: "Reliability and Safety",
    //         value: 12837,
    //         star : 3.57
    //     },
    //     {
    //         name: "Special Fature",
    //         value: 12758,
    //         star : 4.21
    //     },
    //     {
    //         name: "Look",
    //         value: 11298,
    //         star : 4.2
    //     },
    // ]

    const positiveTopics = [
        {
            name: "Softness",
            value: 2.33
        },
        {
            name: "Smoothness",
            value: 7.84
        },
        {
            name: "For Color Dyed Hair",
            value: 2.18
        },
        {
            name: "For Curly/Wavy Hair",
            value: 8.73
        },
        {
            name: "For Thick Hair",
            value: 8.24
        },
        {
            name: "Curling",
            value: 3.29
        },
        {
            name: "Locking",
            value: 3.15
        },
        {
            name: "Results",
            value: 2.95
        }
    ]

    const negativeTopics = [
        {
            name: "handle",
            value: 4.79
        },
        {
            name: "Office and School",
            value: 1.43
        },
        {
            name: "Performance",
            value: 2.18
        },
        {
            name: "Overheat",
            value: 6.89
        },
        {
            name: "Pull hair",
            value: 5.95
        },
        {
            name: "Awkward when using",
            value: 3.89
        }
    ]

    const themesForWC = [
        {
          text: 'told',
          value: 64,
        },
        {
          text: 'mistake',
          value: 11,
        },
        {
          text: 'thought',
          value: 16,
        },
        {
          text: 'bad',
          value: 17,
        },
      ]

    return (
        <Pivot aria-label="Basic Pivot Example" style={{
            marginTop: 15
        }}>
        <PivotItem headerText="General Statistics">
            {!loading ? <DisplayMetrics/> : <DisplayGeneralStats brandLandscape={brandLandscape} ratingDistribution={product_data.ratingDistribution} ratingTrend={product_data.averageRatingByTime} reviewVolume={product_data.reviewVolumeByTime}/>}
        </PivotItem>
        <PivotItem headerText="Aspects Analysis">
            {!loading ? <DisplayMetrics/> : <DisplayTopicStats topicsRankedByTotalMentions={product_data.topicsRankedByTotalMentions} keywordsWiththeMostImpact={product_data.KeywordsWiththeMostImpact} nightyDaysTrend={product_data.NightyDaysTrend} reviewThemes={product_data.ThemesRankedByTotalMentions} positiveTopics={positiveTopics} negativeTopics={negativeTopics}/>}
        </PivotItem>
        <PivotItem headerText="Competitive Analysis">
            {loading ? <DisplayMetrics/> : <DisplayTopicStats themesForWC={themesForWC} reviewThemes={product_data.ThemesRankedByTotalMentions} positiveTopics={positiveTopics} negativeTopics={negativeTopics}/>}
        </PivotItem>
        <PivotItem headerText="VoC Hightlights">
            {loading ? <DisplayMetrics/> : <DisplayVoCHightlights themesForWC={themesForWC} reviewThemes={product_data.ThemesRankedByTotalMentions} positiveTopics={positiveTopics} negativeTopics={negativeTopics}/>}
        </PivotItem>
      </Pivot>
    );
}

export default ProductAnalyticsPage;