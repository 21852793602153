import * as React from 'react';
import HonrizontalBarChart from './basic-components/HorizontalBarChart';

function DisplayVoCHightlights({themesForWC, negativeTopics, positiveTopics, reviewThemes}) {
    

    return (
        <div class="container">
        <div class="row">
            <div class="col">
                <HonrizontalBarChart data={reviewThemes} title={"Review Themes"} seriesName={"#fcc400"}/>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <HonrizontalBarChart data={positiveTopics} title={"Positive Review Topics"} seriesName={"#40a349"}/>
            </div>
            <div class="col">
                <HonrizontalBarChart data={negativeTopics} title={"Negative Review Topics"} seriesName={"#7f3010"}/>
            </div>
        </div>
        </div>
    );

}

export default DisplayVoCHightlights;