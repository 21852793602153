import React, { useState } from "react";
import {Checkbox, Stack, PrimaryButton, Text  } from "@fluentui/react";

function AddProductPage(props) {
    const menuProps = {
        items: [
          {
            key: 'Amazon',
            text: 'Amazon',
          },
          {
            key: 'Google',
            text: 'Google',
          },
          {
            key: 'Walmart',
            text: 'Walmart',
          },
        ],
      };

    return (
        <Stack vertical>
            <Text>Tell me more about your product</Text>
            <input placeholder="ProductId"/>
            <input placeholder="ProductCategory"/>
	        <Stack horizontal>
                <Text>Product Source:</Text>
                <Checkbox label="Amazon" />
                <Checkbox label="Google" />
            </Stack>
            <PrimaryButton text="Submit" />
        </Stack>
    );
}

export default AddProductPage;