import React, { useState, useEffect, Suspense, lazy } from 'react';
import './App.scss';
import { API, Storage } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react-v1';
import { Stack, Text } from "@fluentui/react";
import NavTree from './components/NavTree';
import HeaderBar from './components/HeaderBar';
import mainPage from './components/MainPage';
import projectListPage from './components/ProjectListPage.js'
import productAnalyticsPage from './components/ProductAnalyticsPage';

import NavbarMenu from "./components/NavbarMenu";
import { withRouter, Switch, Route} from "react-router-dom";
import MainPage from './components/MainPage';
import PostGenPage from './components/PostGenPage';

// import dashboard from "./screens/Dashbord/Dashbord";

const initialFormState = { productId: '', productSource: '', productCategory: '', productUrl: '', lastFetchedDate: '' }

window.__DEV__ = true;

function App() {
  const [notes, setNotes] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  const amazonReviewPagePrefix = "https://www.amazon.com/PERLECARE-Adjustable-Weight-Bench-Workout/product-reviews/";
  const amazonReviewPageSuffix = "/ref=cm_cr_dp_d_show_all_btm?ie=UTF8&reviewerType=all_reviews";

  var res = window.location.pathname;
  var baseUrl = process.env.PUBLIC_URL;
  baseUrl = baseUrl.split("/");
  res = res.split("/");
  res = res.length > 0 ? res[baseUrl.length] : "/";
  res = res ? res : "/";
  const activeKey1 = res;

//   return (
//     <div clasName="ms-Grid" dir="ltr">
//       <div>
//         <NavTree />
//       </div>
//       <div className="main-element ms-Grid-col">
//         <div className="ms-Grid-row">Main 11</div>
//         <div className="ms-Grid-row">Main 22</div>
//         <HeaderBar/>
//       </div>
//     </div>
//   );
// }

// return (
//   <Stack horizontal disableShrink>
//     <Stack style={{
//           width: '10%',
//         }}>
//       <NavTree/>
//     </Stack>
//     <Stack style={{
//           width: '60%',
//         }}>
//       <HeaderBar/>
//       <MainPage/>
//     </Stack>
//   </Stack>
// );
// }
return (
  <div id="wrapper">
    {/* <Stack horizontal disableShrink>
     <Stack style={{
          width: '10%',
        }}>
      <NavTree/>
    </Stack>
    <Stack style={{
          width: '60%',
        }}>
      <HeaderBar/>
      <MainPage/>
    </Stack>
   </Stack> */}

    <NavbarMenu activeKey={activeKey1}/>
     <div id="main-content" style={{
            width: "calc(100% - 250px)",
            float: "right",
            position:"relative",
            marginTop: "65px",
            padding: "0 10px"
     }}>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dashboard`}
          component={projectListPage}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-location`}
          component={productAnalyticsPage}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/PostGen`}
          component={PostGenPage}
        />
      </Switch>
    </div> 
  </div>
);
}

export default withRouter(App);